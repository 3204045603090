:root {
  --primaryColor: #c8102e;
  --dangerColor: #ff580a;
  --sidebarColor: #504c53;
  --colorBackground: #efeeec;
  --textInPrimaryBackground: #fff;
  --apexDotColor: #fff;
  --checkColor: #c8102e;
  --apexBorderColor: #c8102e;
}
