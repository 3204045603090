* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  // LAYOUT
  --fontFamily: "AE", sans-serif;
}
.blockImportant {
  overflow-y: hidden !important;
  a {
    display: block !important;
  }
}

.btn.lightBlue {
  background-color: var(--primaryColor);
  color: #fff;
  font-weight: 500;
  margin-bottom: 1.5rem;
  max-width: 35%;
  text-transform: uppercase;
  // margin-right: 1.5rem;
  &:hover {
    background-color: var(--primaryColor);
    color: #fff;
    filter: saturate(0.7);
  }
  &:disabled {
    filter: opacity(0.5);
    background-color: var(--primaryColor);
    color: #fff;
  }
  &.blocked {
    filter: opacity(0.3);
    background-color: var(--primaryColor);
    color: #fff;
    cursor: default;
  }
}
.btn.delete {
  background-color: var(--dangerColor);
  color: #fff;
  font-weight: 500;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--dangerColor);
    color: #fff;
    filter: saturate(0.7);
  }
  &:disabled {
    filter: opacity(0.5);
    background-color: var(--dangerColor);
    color: #fff;
  }
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 6px;
  }
}
.select-country {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(44, 56, 74, 0.95);
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: #fff;
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid #b1b7c1;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 0.25rem;
}
